import { elementsPackageI18nNamespaces } from '@elements-financial/elements';

export const allI18NNamespaces = [
  'acceptInvite',
  'accountDetails',
  'addNetWorthItem',
  'auth',
  'client',
  'clients',
  'display',
  'editNotes',
  'error',
  'forAdvisor',
  'guides',
  'inAppMessaging',
  'insight',
  'insights',
  'integrations',
  'invitations',
  'onePagePlan',
  'reports',
  'saod',
  'signIn',
  'taxes',
  'form',
  'settings',
  'dashboard',
  ...elementsPackageI18nNamespaces,
];
